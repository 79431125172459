export const IconTablerUniverse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-universe">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7.027 11.477a5 5 0 1 0 5.496 -4.45a4.951 4.951 0 0 0 -3.088 .681" />
    <path d="M5.636 5.636a9 9 0 1 0 3.555 -2.188" />
    <path d="M18 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
    <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
    <path d="M9 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
  </svg>
);

export const IconTablerMinimize = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-minimize">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 19v-2a2 2 0 0 1 2 -2h2" />
    <path d="M15 5v2a2 2 0 0 0 2 2h2" />
    <path d="M5 15h2a2 2 0 0 1 2 2v2" />
    <path d="M5 9h2a2 2 0 0 0 2 -2v-2" />
  </svg>
);

export const IconTablerRefreshDot = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-refresh-dot">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
    <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
  </svg>
);

export const IconTablerAssembly = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-assembly">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
    <path d="M15.5 9.422c.312 .18 .503 .515 .5 .876v3.277c0 .364 -.197 .7 -.515 .877l-3 1.922a1 1 0 0 1 -.97 0l-3 -1.922a1 1 0 0 1 -.515 -.876v-3.278c0 -.364 .197 -.7 .514 -.877l3 -1.79c.311 -.174 .69 -.174 1 0l3 1.79h-.014z" />
  </svg>
);

export const IconTablerAtom2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-atom-2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M12 21l0 .01" />
    <path d="M3 9l0 .01" />
    <path d="M21 9l0 .01" />
    <path d="M8 20.1a9 9 0 0 1 -5 -7.1" />
    <path d="M16 20.1a9 9 0 0 0 5 -7.1" />
    <path d="M6.2 5a9 9 0 0 1 11.4 0" />
  </svg>
);

export const IconTablerKarate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-karate">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M18 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
    <path d="M3 9l4.5 1l3 2.5" />
    <path d="M13 21v-8l3 -5.5" />
    <path d="M8 4.5l4 2l4 1l4 3.5l-2 3.5" />
  </svg>
);

export const IconTablerUserScan = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-user-scan">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
    <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
    <path d="M4 16v2a2 2 0 0 0 2 2h2" />
    <path d="M16 4h2a2 2 0 0 1 2 2v2" />
    <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
    <path d="M8 16a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2" />
  </svg>
);

export const IconTablerUserCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-user-circle">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
  </svg>
);

export const IconTablerProgressBolt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-progress-bolt">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969" />
    <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554" />
    <path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592" />
    <path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305" />
    <path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356" />
    <path d="M12 9l-2 3h4l-2 3" />
  </svg>
);

export const IconTablerFreeRights = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-free-rights">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M13.867 9.75c-.246 -.48 -.708 -.769 -1.2 -.75h-1.334c-.736 0 -1.333 .67 -1.333 1.5c0 .827 .597 1.499 1.333 1.499h1.334c.736 0 1.333 .671 1.333 1.5c0 .828 -.597 1.499 -1.333 1.499h-1.334c-.492 .019 -.954 -.27 -1.2 -.75" />
    <path d="M12 7v2" />
    <path d="M12 15v2" />
    <path d="M6 6l1.5 1.5" />
    <path d="M16.5 16.5l1.5 1.5" />
  </svg>
);

export const IconTablerAffiliate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-affiliate">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275" />
    <path d="M11.683 12.317l5.759 -5.759" />
    <path d="M5.5 5.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0" />
    <path d="M18.5 5.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0" />
    <path d="M18.5 18.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0" />
    <path d="M8.5 15.5m-4.5 0a4.5 4.5 0 1 0 9 0a4.5 4.5 0 1 0 -9 0" />
  </svg>
);

export const IconTablerFileDigit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-file-digit">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    <path d="M9 12m0 1a1 1 0 0 1 1 -1h1a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1z" />
    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    <path d="M15 12v5" />
  </svg>
);

export const IconTablerTruckLoading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-truck-loading">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M2 3h1a2 2 0 0 1 2 2v10a2 2 0 0 0 2 2h15" />
    <path d="M9 6m0 3a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3z" />
    <path d="M9 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M18 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  </svg>
);

export const IconTablerSpeedtest = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-icon-tabler-brand-speedtest">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5.636 19.364a9 9 0 1 1 12.728 0" />
    <path d="M16 9l-4 4" />
  </svg>
);

export const IconTablerCalendarCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-calendar-check">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M11.5 21h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6" />
    <path d="M16 3v4" />
    <path d="M8 3v4" />
    <path d="M4 11h16" />
    <path d="M15 19l2 2l4 -4" />
  </svg>
);

export const IconTablerAccessPoint = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-access-point">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12l0 .01" />
    <path d="M14.828 9.172a4 4 0 0 1 0 5.656" />
    <path d="M17.657 6.343a8 8 0 0 1 0 11.314" />
    <path d="M9.168 14.828a4 4 0 0 1 0 -5.656" />
    <path d="M6.337 17.657a8 8 0 0 1 0 -11.314" />
  </svg>
);

export const IconTablerFileIsr = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] icon icon-tabler icons-tabler-outline icon-tabler-file-isr">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 3v4a1 1 0 0 0 1 1h4" />
    <path d="M15 3v4a1 1 0 0 0 1 1h4" />
    <path d="M6 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
    <path d="M3 15l3 -3l3 3" />
  </svg>
);